import { Component, Vue, Watch } from 'vue-property-decorator';

import { SidemenuItem } from '@/components/sidemenu/Sidemenu';

import { userRoles } from '@/models/User';
import { PermissionSlug } from '@/support/PermissionSlug';

@Component<Dashboard>({})
export default class Dashboard extends Vue {
  public $pageTitle = 'Dossier statistieken';

  protected isLoading = true;

  protected userRoles = userRoles;

  protected items: SidemenuItem[] = [
    {
      title: 'Trillingstool',
      link: '/tools/trillingstool',
      icon: {
        key: 'assignment',
      },
      visible: true,
    },
    {
      title: 'Trillingstool Waardedaling',
      link: '/tools/trillingstool-waardedaling',
      icon: {
        key: 'assignment',
      },
      visible: this.$store.state.Auth.hasRole(this.userRoles.ObjectionRoles) && false, // disable for now
    },
    {
      title: 'PeTAT®',
      link: '/effectgebied',
      icon: {
        key: 'landscape',
      },
      visible: this.$store.state.Auth.hasRole(this.userRoles.ObjectionRoles),
    },
    {
      title: 'Projecten',
      link: '/projecten',
      icon: {
        key: 'folder_shared',
      },
      visible: this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles) && this.$store.state.isServiceOrganization && this.canListProjects,
    },
    {
      title: 'Export Excel',
      link: '/tools/export-excel',
      icon: {
        key: 'cloud_download',
      },
      visible: true,
    },
    {
      title: 'Licentie overzicht',
      link: '/tools/licentie-overzicht',
      icon: {
        key: 'euro',
      },
      visible: this.$store.state.Auth.hasRole(this.userRoles.AdminRoles) && this.$store.state.isServiceOrganization,
    },
    {
      title: 'Import VES regeling',
      link: '/tools/ves-import-tool',
      icon: {
        key: 'cloud_download',
      },
      visible: true,
    },
    {
      title: 'Opname export',
      link: '/tools/opname-export',
      icon: {
        key: 'cloud_download',
      },
      visible: this.$store.state.Auth.hasRole(this.userRoles.AdminRoles) && this.$store.state.isServiceOrganization,
    },
  ];

  protected old: SidemenuItem[] = [
    {
      title: 'Planningchecker',
      link: '/tools/planning-checker',
      icon: {
        key: 'assignment',
      },
      visible: this.$store.state.Auth.hasRole(this.userRoles.PlanningRoles) && this.$store.state.isServiceOrganization,
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected get canListProjects(): boolean {
    return this.$store.state.Auth.can(PermissionSlug.PROJECTS_LIST);
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Tools' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
